





















import { Component, Mixins, Prop } from "vue-property-decorator";
import ProjectForm from "@/components/project/ProjectForm.vue";
import { ProjectInterface, TagInterface } from "@/types/project.type";
import { ProjectModule } from "@/store/modules/project-module";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import ProjectBreadcrumb from "@/components/project/ProjectBreadcrumb.vue";
import { RoleMixin } from "@/mixins/role-mixin";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";

@Component({
  components: {
    ProjectForm,
    ConfirmationDialog,
    ProjectBreadcrumb,
    DeleteButton,
  },
})
export default class ProjectSetting extends Mixins(RoleMixin) {
  public $refs!: {
    projectForm: HTMLFormElement;
    deleteDialog: HTMLFormElement;
  };

  @Prop({
    required: true,
  })
  private readonly id!: number;

  private editModeStatus: boolean = true;

  private projectData: ProjectInterface = {
    id: undefined,
    client: undefined,
    name: "",
    remark: "",
    contactPersonName: "",
    contactPersonEmail: "",
    billedDuration: 0,
    totalDuration: 0,
    startDate: "",
    canEdit: false,
    tags: [],
    autoApproveTimesheet: false,
    alertCount: 0,
    billable: false,
    approvedDuration: 0,
    rejectedDuration: 0,
    pendingDuration: 0,
  };

  private async created() {
    await ProjectModule.getProjectById(this.id as number);
    this.projectData = { ...(ProjectModule.project as ProjectInterface) };
    this.projectData.tags = (this.projectData.tags as TagInterface[]).map((item) => item.id) as number[];
  }

  private openDeleteProjectDialog() {
    this.$refs.deleteDialog.openDialog();
  }

  private afterSubmit() {
    this.$router.push({
      name: "Project Detail",
      params: {
        id: this.id.toString(),
      },
    });
  }

  private cancel() {
    this.$router.push(`/project/${this.$route.params.id}`);
  }

  private async deleteProject() {
    try {
      await ProjectModule.deleteProject(this.id as number);
    } catch (exp) {
      const error = exp.toJSON();
      await SnackbarModule.setSnack({
        color: "error",
        message: error.message,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete project successfully!",
    });
    await this.$router.push({
      name: "Project List",
    });
  }
}
